import { QuestionDto } from './iquestion';

export enum Language {
  en = 'en',
  se = 'se',
  pt = 'pt',
  es = 'es',
  nl = 'nl',
}

export interface CreateModuleDto {
  title: string;
  titleKey?: string;
  description?: string;
  descriptionKey?: string;
  video?: Record<Language, string | undefined>;
  text?: Record<Language, string | undefined>;
  questions: QuestionDto[];
  durationMin: number;
}

export interface ReadModuleDto extends CreateModuleDto {
  _id: string;
  createdAt: string;
  updatedAt: string;
}
