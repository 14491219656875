import styles from './Card.module.css';

export interface CardProps {
  children?: React.ReactNode | React.ReactNode[];
  image?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const Card = (props: CardProps) => {
  const { children, image, disabled, onClick } = props;
  return (
    <div
      className={`${styles.container} ${onClick && !disabled ? styles.clickable : ''} ${disabled ? styles.disabled : ''}`}
      onClick={disabled ? undefined : onClick}
    >
      {image && (
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image} alt="" />
        </div>
      )}
      <div className={styles.childContainer}>{children}</div>
    </div>
  );
};
