export const HamburgerMenu = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.44772 0.44772 0 1 0H15C15.5523 0 16 0.44772 16 1C16 1.55228 15.5523 2 15 2H1C0.44772 2 0 1.55228 0 1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 6.4477 6.4477 6 7 6H15C15.5523 6 16 6.4477 16 7C16 7.5523 15.5523 8 15 8H7C6.4477 8 6 7.5523 6 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 12.4477 0.44772 12 1 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H1C0.44772 14 0 13.5523 0 13Z"
        fill="currentColor"
      />
    </svg>
  );
};