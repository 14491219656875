import React from 'react';
import styles from './ProgressBar.module.css';
import { Text } from '../../atoms';

interface ProgressBarProps {
  value: number;
  maxValue: number;
  unit: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  maxValue,
  unit,
}) => {
  const percentage = (value / maxValue) * 100;
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${percentage}%` }} />
      </div>
      <Text
        text={`${value} ${unit}`}
        fontSize="titleSmall"
        fontWeight="medium"
      />
    </div>
  );
};
