import { Icons } from '../../assets/icons';
import { Text } from '../../atoms';
import { getTextVariant } from './Checkbox.helper';
import StyledClass from './Checkbox.module.css';

interface CheckboxProps {
  text: string;
  textKey?: string;
  checked: boolean;
  isAlternativeCorrect?: boolean;
  isDisabled?: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    text,
    textKey,
    checked,
    isAlternativeCorrect,
    isDisabled = false,
    onChange,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const textVariant = getTextVariant(checked, isAlternativeCorrect);
  const isBehaviourDisabled = isAlternativeCorrect !== undefined || isDisabled;

  return (
    <div className={`${StyledClass.container}`}>
      <label
        className={`${StyledClass.label} ${isBehaviourDisabled ? StyledClass.disabled : ''}`}
      >
        <div className={StyledClass.highlightUnderlay} />
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className={`${StyledClass.checkbox} ${StyledClass[textVariant ?? 'waiting']}`}
          disabled={isBehaviourDisabled}
        ></input>

        {isAlternativeCorrect === undefined || textVariant === 'success' ? (
          <Icons.Checkmark className={StyledClass.checkboxIcon} />
        ) : (
          <Icons.Dash className={StyledClass.checkboxIcon} />
        )}
        <Text
          text={text}
          key={textKey}
          fontSize="bodyLarge"
          fontWeight="medium"
          variant={textVariant}
        />
      </label>
    </div>
  );
};
