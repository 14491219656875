/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import { FloatingButton } from '../../atoms/FloatingButton';
import styles from './HamburgerMenuButton.module.css';

export interface HamburgerMenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

type AnimationType = 'ANIMATE_OPEN' | 'ANIMATE_CLOSE';
const ANIMATION_DURATION = 300;

export const HamburgerMenuButton = (props: HamburgerMenuButtonProps) => {
  const { isOpen: outerIsOpen, onClick } = props;

  const [internalIsOpen, setIsInternalOpen] = useState<
    boolean | AnimationType | undefined
  >(undefined);

  const animateHamBurgerOut = internalIsOpen === 'ANIMATE_OPEN';
  const animateCrossOut = internalIsOpen === 'ANIMATE_CLOSE';
  const animateHamburgerIn = internalIsOpen === false;
  const isOpen = internalIsOpen === true || internalIsOpen === 'ANIMATE_CLOSE';

  useEffect(() => {
    if (outerIsOpen) {
      setIsInternalOpen('ANIMATE_OPEN');
      setTimeout(() => {
        setIsInternalOpen(true);
      }, ANIMATION_DURATION);
    } else {
      if (internalIsOpen === undefined) return;
      setIsInternalOpen('ANIMATE_CLOSE');
      setTimeout(() => {
        setIsInternalOpen(false);
      }, ANIMATION_DURATION);
    }
  }, [outerIsOpen]);

  return (
    <FloatingButton
      className={`${styles.hamburgerMenuContainer} ${isOpen ? styles.open : ''}    `}
      onClick={onClick}
      content={
        isOpen ? (
          <Icons.Cross
            className={`${styles.crossIcon} ${animateCrossOut && styles.animateCrossOut}`}
          />
        ) : (
          <Icons.HamburgerMenu
            className={`${styles.hamburgerIcon} ${animateHamBurgerOut && styles.animateHamburgerOut} ${animateHamburgerIn && styles.animateHamburgerIn}`}
          />
        )
      }
    />
  );
};