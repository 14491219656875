import { BadgeType } from '../../atoms/Badge';
import { CourseStatus, UserCourseResult } from '../../utils/interfaces';

export const getBadgeType = (course: UserCourseResult): BadgeType => {
  switch (course.status) {
    case CourseStatus.Completed:
      return BadgeType.completed;
    case CourseStatus.Ongoing:
      return BadgeType.active;
    case CourseStatus.Todo:
      return BadgeType.notStarted;
  }
};
