import { LanguageCode } from '@mono/common';
import { Provider } from '../../atoms/VideoEmbedded';
import { Language } from '../../utils/interfaces';

const languageCodeToEnum: Record<LanguageCode, Language> = {
  en: Language.en,
  se: Language.se,
  nl: Language.nl,
};

export const getVideoProvider = (videoUrl: string): Provider | undefined => {
  if (/youtube\.com|youtu\.be/.test(videoUrl)) {
    return 'youtube';
  } else if (/loom\.com/.test(videoUrl)) {
    return 'loom';
  }
};

export const convertLanguageCodeToEnum = (code: LanguageCode): Language => {
  return languageCodeToEnum[code];
};
