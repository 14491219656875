import BlueBackground from './BackgroundBlue.png';
import WomanThatJumpsAtHome from './woman-that-jumps-at-home.svg';
import CosafeTrainingSession from './cosafe-training-session.jpg';
import LinesBlue from './lines--blue.svg';
import GreyShapes from './GreyShapes.svg';
import NotFound from './not-found.svg';

export const Images = {
  BlueBackground,
  WomanThatJumpsAtHome,
  CosafeTrainingSession,
  LinesBlue,
  GreyShapes,
  NotFound,
};
