import { ReadModuleDto } from './icourseModule';
import { CourseStatus } from './userCourseResult';

export enum CourseType {
  Base = 'Base',
  Test = 'Test',
  Premium = 'Premium',
}

export interface CreateCourseDto {
  title: string;
  titleKey?: string;
  description: string;
  descriptionKey?: string;
  type: CourseType;
  modules: ReadModuleDto[];
}

export interface ReadCourseSurfaceDto extends Omit<CreateCourseDto, 'modules'> {
  _id: string;
  moduleIds: string[];
  enabled: boolean;
  totalDurationMin: number;
  createdAt: string;
  updatedAt: string;
  status: CourseStatus;
}

export interface ReadCourseDto extends ReadCourseSurfaceDto {
  modules: ReadModuleDto[];
}
