import { BustInSilhouette } from './BustInSilhouette';
import { Calendar } from './Calendar';
import { CheckmarkRound } from './CheckmarkRound';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { ChevronRightRound } from './ChevronRightRound';
import { ChevronUp } from './ChevronUp';
import { ChevronDown } from './ChevronDown';
import { Clock } from './Clock';
import { CrossRound } from './CrossRound';
import { Document } from './Document';
import { Door } from './Door';
import { Envelope } from './Envelope';
import { Eye } from './Eye';
import { EyeCrossed } from './EyeCrossed';
import { GreatBritainFlag } from './flags/GreatBritainFlag';
import { Hourglass } from './Hourglass';
import { LeftArrow } from './LeftArrow';
import { RightArrow } from './RightArrow';
import { Loader } from './Loader';
import { Lock } from './Lock';
import { MagnifyingGlass } from './MagnifyingGlass';
import { NetherlandsFlag } from './flags/NetherlandsFlag';
import { RadioChecked } from './RadioChecked';
import { SwedenFlag } from './flags/SwedenFlag';
import { Cards } from './Cards';
import { CirclePartial } from './CirclePartial';
import { Circle } from './Circle';
import { CheckmarkRoundFilled } from './CheckmarkRoundFilled';
import { Crown } from './Crown';
import { HamburgerMenu } from './HamburgerMenu';
import { Cross } from './Cross';
import { Checkmark } from './Checkmark';
import { Dash } from './Dash';
import { GlobalSearch } from './GlobalSearch';

export const Icons = {
  flag: {
    Netherlands: NetherlandsFlag,
    GreatBritain: GreatBritainFlag,
    Sweden: SwedenFlag,
  },
  BustInSilhouette,
  Calendar,
  CheckmarkRound,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronRightRound,
  ChevronUp,
  Clock,
  CrossRound,
  Document,
  Door,
  Envelope,
  Eye,
  EyeCrossed,
  Hourglass,
  LeftArrow,
  RightArrow,
  CirclePartial,
  Lock,
  MagnifyingGlass,
  RadioChecked,
  Cards,
  Loader,
  Circle,
  CheckmarkRoundFilled,
  Crown,
  HamburgerMenu,
  Cross,
  Checkmark,
  Dash,
  GlobalSearch,
};
