import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { LanguageCode } from '@mono/common';
import styles from './CourseModule.module.css';
import { ThumbnailProps, VideoCard } from '../../molecules/VideoCard';
import { Card, Text } from '../../atoms';
import { QuizComponent } from '../QuizComponent';
import { Skeleton, ScrollableText } from '../../molecules';
import {
  convertLanguageCodeToEnum,
  getVideoProvider,
} from './CourseModule.helper';
import { Language, UserModuleResult } from '../../utils/interfaces';
import { Icons } from '../../assets/icons';
import { Brand } from '../../assets/brand';
import { Images } from '../../assets/images';

interface CourseModuleProps {
  module: UserModuleResult;
  isLastModule: boolean;
  languageCode: LanguageCode;
  doSaveProgress: () => void;
  onContinue: () => void;
  onGoBack: () => void;
  shuffleQuestionsAlternatives: () => void;
}

interface CourseModuleInstructionsText {
  text: string;
  textKey: string;
}

export const CourseModule = ({
  module,
  isLastModule,
  languageCode,
  doSaveProgress,
  onContinue,
  onGoBack,
  shuffleQuestionsAlternatives,
}: CourseModuleProps) => {
  const language: Language = convertLanguageCodeToEnum(languageCode);
  const isVideoModule = !!module.video;
  const isTextModule = !!module.text;
  const isImageModule = false;
  const text: string | undefined = module.text?.[language];
  const videoUrl: string | undefined = module.video?.[language];
  const thumbnail: ThumbnailProps = {
    imageUrl: Brand.CosafeBook,
    backgroundImageUrl: Images.BlueBackground,
    playVideoTitle: 'Play video',
    playVideoTitleKey: 'module_button_playVideo',
  };

  const onGoBackClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onGoBack?.();
  };

  const getMissingContainer = (textKey: string, text: string) => {
    return (
      <Card>
        <Text
          textKey={textKey}
          text={text}
          fontSize="bodyLarge"
          variant="attention"
        />
      </Card>
    );
  };

  const getTextContainer = () => {
    if (!text) {
      return getMissingContainer(
        'module_text_notAvailable',
        'Text is not available in the chosen language',
      );
    }
    return (
      <div className={styles.textContainer}>
        <ScrollableText text={text} />
      </div>
    );
  };

  const getVideoContainer = () => {
    if (!videoUrl) {
      return getMissingContainer(
        'module_video_notAvailable',
        'Video is not available in the chosen language',
      );
    }
    return (
      <div className={styles.videoContainer}>
        <VideoCard
          videoUrl={videoUrl}
          title={module.title}
          provider={getVideoProvider(videoUrl)}
          thumbnail={thumbnail}
        />
      </div>
    );
  };

  const getInstructions = (): CourseModuleInstructionsText | undefined => {
    if (isVideoModule) {
      return {
        text: 'Watch the video and answer the questions to complete this module',
        textKey: 'module_video_instructions',
      };
    }

    if (isTextModule) {
      return {
        text: 'Read the text below and answer the questions to complete this module',
        textKey: 'module_text_instructions',
      };
    }

    if (isImageModule) {
      return {
        text: 'Look at the picture and answer the questions to complete this module',
        textKey: 'module_image_instructions',
      };
    }
  };

  const instructions = getInstructions();

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Link to={'..'} onClick={onGoBackClick}>
          <Icons.LeftArrow />
        </Link>
        <div className={styles.headerTitleContainer}>
          <div className={styles.headerTitle}>
            <Text
              testId="module-title"
              text={module.title}
              textKey={module.titleKey}
              fontSize="h2"
              fontWeight="bold"
            />
          </div>
          {!!instructions && (
            <Text
              testId="module-instructions"
              text={instructions.text}
              textKey={instructions.textKey}
              fontSize="bodyLarge"
              fontWeight="medium"
            />
          )}
        </div>
      </div>
      {isVideoModule && getVideoContainer()}
      {isTextModule && getTextContainer()}
      <div className={styles.questionContainer}>
        <QuizComponent
          isReviewMode={module.completed}
          isLastModule={isLastModule}
          questions={module.questions}
          doSaveProgress={doSaveProgress}
          onContinue={onContinue}
          shuffleQuestionsAlternatives={shuffleQuestionsAlternatives}
        />
      </div>
    </div>
  );
};

export const CourseModuleLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Link to={'/'}>
          <Icons.LeftArrow />
        </Link>
        <Skeleton type="h2" />
      </div>
      <div className={`${styles.videoContainer} ${styles.loaderContainer}`}>
        <Skeleton type="card" />
      </div>
    </div>
  );
};
