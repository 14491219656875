/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Card, InputField, Text } from '../../atoms';
import { LinkTo } from '../../atoms/LinkTo';
import styles from './Login.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  redirectToSso,
  login,
} from '../../redux/Login/LoginSlice/actionCreators';
import { AppDispatch } from '../../store';
import { getItem } from '../../utils/storage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  selectLoginError,
  selectLoginIsLoading,
  selectUser,
  setIsError,
} from '../../redux/Login/LoginSlice';
import { useFeatureFlags } from '../../utils/feature-flags/FeatureFlagProvider';
import { Utils } from '@mono/common';
import { setLoading } from '../../redux/LoadingSlice';
import { LoginHeader } from '../LoginHeader';
import { getSsoRedirectLink } from './Login.helper';
import { Modals } from '../../providers';

interface LoginProps {
  mode: 'login' | 'sso';
}

export interface LoginForm {
  email: string;
  password: string;
}

export const Login = (props: LoginProps) => {
  const { enableSsoLogin } = useFeatureFlags();
  const mode = enableSsoLogin ? props.mode : 'login';
  const [searchParams] = useSearchParams();

  const isCosafeLogin = mode === 'login';
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const [isEmailValid, setIsEmailValid] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  useSelector(selectUser);
  const isLoading = useSelector(selectLoginIsLoading);
  const loginError = useSelector(selectLoginError);

  const [validationErrors] = useState({
    email: '',
    password: '',
  });

  const user = getItem('user');

  useEffect(() => {
    dispatch(setLoading(!!isLoading));
  }, [isLoading]);

  useEffect(() => {
    dispatch(setIsError(null));
  }, [inputs]);

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user]);

  useEffect(() => {
    getSsoRedirectLink(searchParams, true, dispatch);
  }, [searchParams]);

  useEffect(() => {
    if (loginError) {
      Modals.ShowSnack({
        textKey: loginError,
        text: 'Something went wrong. Please try again later.',
        type: 'error',
      });
    }
  }, [loginError]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value, name } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'email') {
      let errorMessage = '';
      const isValidEmail = Utils.isValidEmail(value);

      if (value && !isValidEmail) {
        errorMessage = 'incorrect_email';
      }
      setIsEmailValid(isValidEmail);
      validationErrors.email = errorMessage;
    }
  };

  const handleSubmit = () => {
    try {
      if (isCosafeLogin) {
        dispatch(login({ email: inputs.email, password: inputs.password }));
      } else {
        dispatch(redirectToSso(inputs.email));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <div className={styles.childContainer}>
          <LoginHeader />
          <div>
            <Text
              text="Welcome to"
              textKey="login_welcome"
              fontSize="h1"
              fontWeight="bold"
            />
          </div>
          <div>
            <Text text="Cosafe Academy!" fontSize="h1" fontWeight="bold" />
          </div>
          <InputField
            label="Email Address"
            labelKey="login_email_label"
            helperText="Enter email"
            helperTextKey="login_email_helper"
            type="email"
            name="email"
            value={inputs.email}
            onChange={onChange}
            errorTextKey={validationErrors.email}
          />
          {isCosafeLogin && (
            <InputField
              label="Password"
              labelKey="login_password_label"
              helperText="Enter password"
              helperTextKey="login_password_helper"
              type="password"
              name="password"
              value={inputs.password}
              onChange={onChange}
              errorTextKey={loginError ?? undefined}
            />
          )}
          {isCosafeLogin && (
            <div className={styles.link}>
              <LinkTo
                text="Forgot password?"
                textKey="login_forgot_password"
                to="/forgotPassword"
              />
            </div>
          )}
          <div className={styles.button}>
            <Button
              disabled={!isEmailValid || (isCosafeLogin && !inputs.password)}
              text="Log in"
              textKey="login_button"
              onClick={handleSubmit}
              size="full"
            />
          </div>
          {enableSsoLogin && (
            <div className={styles.button}>
              <Button
                type={'secondary'}
                text={
                  mode === 'login'
                    ? 'Single Sign-On (SSO)'
                    : 'Login with Cosafe'
                }
                textKey={
                  mode === 'login' ? 'login_with_sso' : 'login_with_password'
                }
                onClick={() => navigate(mode === 'login' ? '/sso' : '/login')}
                size="full"
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
