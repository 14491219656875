export const Crown = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="16"
      height="10"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 0.545412L2.60999 2.90541L5.00025 0.515137L7.39055 2.90541L9.75 0.545922V4.98426C9.75 5.81271 9.07845 6.48426 8.25 6.48426H1.75C0.92157 6.48426 0.25 5.81271 0.25 4.98426V0.545412ZM8.75 2.95406V4.98426C8.75 5.26036 8.52615 5.48426 8.25 5.48426H1.75C1.47386 5.48426 1.25 5.26036 1.25 4.98426V2.95356L2.60998 4.31356L5.00025 1.92328L7.39055 4.31356L8.75 2.95406Z"
        fill="currentColor"
      />
    </svg>
  );
};
