export const CirclePartial = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C9 1.44771 9.44772 0.999999 10 0.999999C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 7.51319 2.00991 5.26037 3.63957 3.63251C4.03031 3.2422 4.66347 3.24255 5.05378 3.63329C5.44409 4.02403 5.44374 4.65719 5.053 5.0475C3.78354 6.31557 3 8.06533 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.134 13.866 3 10 3C9.44772 3 9 2.55228 9 2Z"
        fill="currentColor"
      />
    </svg>
  );
};
