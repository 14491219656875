import { randomInt } from './mock.helpers';
import {
  CourseStatus,
  CourseType,
  UserCourseResult,
  UserModuleResult,
} from '../interfaces';

export const getMockedUserCourseResult = (
  partial: Partial<UserCourseResult>,
): UserCourseResult => {
  return {
    _id: `Course-${randomInt(0, 100000)}`,
    moduleIds: ['31243243', '4534543534'],
    totalDurationMin: 15,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    title: `Title ${randomInt(0, 100000)}`,
    description: `Description ${randomInt(0, 100000)}`,
    type: CourseType.Base,
    modules: [getMockedUserModuleResult({})],
    status: CourseStatus.Completed,
    enabled: true,
    ...partial,
  };
};

export const getMockedUserModuleResult = (
  partial: Partial<UserModuleResult>,
): UserModuleResult => {
  return {
    _id: `Id-${randomInt(0, 100000)}`,
    moduleId: `Module-${randomInt(0, 100000)}`,
    completed: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    title: 'Title',
    questions: [],
    durationMin: 10,
    ...partial,
  };
};
