import React, { useEffect, useState } from 'react';
import styles from './Courses.module.css';
import {
  CourseStatus,
  CourseType,
  UserCourseResult,
} from '../../utils/interfaces';
import {
  CourseCard,
  HeroSection,
  PaginationControls,
  Skeleton,
} from '../../molecules';
import { PAGINATION_LIMIT__COURSES } from '../../config/consts';
import { StaticMenu, MenuOption } from '../../molecules/StaticMenu';
import { Icons } from '../../assets/icons';
import { InputField, Text } from '../../atoms';
import {
  CoursePaginationResponse,
  CourseStatusCounts,
} from '../../utils/interfaces/academy-api/icoursePaginationResponse';

interface CoursesProps {
  name: string;
  courses: UserCourseResult[] | undefined;
  courseStatusCounts: CourseStatusCounts | undefined;
  coursePremiumCount: number | undefined;
  selectedMenuItem?: string;
  highlightedCourse?: UserCourseResult | null;
  paginationData?: Awaited<CoursePaginationResponse>['meta'];
  onChangeCourseStatusClick: (nextCourseStatus: CourseStatus) => void;
  onChangeCourseTypeClick: (nextCourseType: CourseType) => void;
  onCourseCardClick: (course: UserCourseResult) => void;
  onChangePageClick: (nextPage: number) => void;
  onChangeSearch: (nextSearchText: string) => void;
}

export const Courses: React.FC<CoursesProps> = ({
  name,
  courses,
  selectedMenuItem,
  courseStatusCounts,
  coursePremiumCount,
  highlightedCourse,
  paginationData,
  onChangeCourseStatusClick,
  onChangeCourseTypeClick,
  onCourseCardClick: onCourseCardClickProp,
  onChangePageClick,
  onChangeSearch,
}) => {
  const [searchInputState, setSearchInputState] = useState<string | undefined>(
    paginationData?.search ?? '',
  );

  const menuOptions: MenuOption[] = [
    {
      key: CourseStatus.Ongoing,
      textKey: 'courses_menu_in_progress',
      count: courseStatusCounts?.[CourseStatus.Ongoing] ?? null,
      icon: <Icons.CirclePartial />,
    },
    {
      key: CourseStatus.Todo,
      textKey: 'courses_menu_not_started',
      count: courseStatusCounts?.[CourseStatus.Todo] ?? null,
      icon: <Icons.Calendar />,
    },
    {
      key: CourseStatus.Completed,
      textKey: 'courses_menu_completed',
      count: courseStatusCounts?.[CourseStatus.Completed] ?? null,
      icon: <Icons.CheckmarkRound />,
    },
    {
      textKey: 'courses_menu_premium',
      key: CourseType.Premium,
      count: courseStatusCounts ? coursePremiumCount : null,
      icon: <Icons.Crown />,
    },
  ];

  useEffect(() => {
    setSearchInputState(paginationData?.search ?? '');
  }, [paginationData?.search]);

  const onChangeMenuItem = (menuItem: string) => {
    const isCourseStatus = CourseStatus[menuItem as keyof typeof CourseStatus];
    const isCourseType = CourseType[menuItem as keyof typeof CourseType];

    if (isCourseStatus) {
      onStatusChange(menuItem);
    } else if (isCourseType) {
      onTypeChange(menuItem);
    }
  };

  const onStatusChange = (nextStatus: string) => {
    const nextCourseStatus =
      CourseStatus[nextStatus as keyof typeof CourseStatus];
    onChangeCourseStatusClick(nextCourseStatus);
  };

  const onTypeChange = (nextType: string) => {
    const nextCourseType = CourseType[nextType as keyof typeof CourseType];
    onChangeCourseTypeClick(nextCourseType);
  };

  const onSearchChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const nextSearchText = changeEvent.target.value;
    setSearchInputState(nextSearchText);
    onChangeSearch?.(nextSearchText);
  };

  const onCourseCardClick = (course: UserCourseResult) => {
    onCourseCardClickProp(course);
  };

  const notFoundBySearchComponent = () => {
    return (
      <div className={styles.notFoundBySearchCourseContainer}>
        <Icons.GlobalSearch />
        <Text
          text="Nothing found"
          textKey="courses_not_found"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
        <Text
          text="Check your search request or try again"
          textKey="courses_not_found_try_again"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
      </div>
    );
  };

  const emptyCoursesComponent = () => {
    return (
      <div className={styles.noCourseContainer}>
        <Icons.Cards />
        <Text
          text="No courses here yet"
          textKey="courses_none"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
        <Text
          text="Check back later or contact your administrator"
          textKey="courses_none_contact"
          variant="discrete"
          fontSize="body"
          fontWeight="medium"
        />
      </div>
    );
  };

  return (
    <div className={styles.page}>
      <HeroSection
        course={highlightedCourse}
        name={name}
        onGoToCourse={() =>
          highlightedCourse ? onCourseCardClick(highlightedCourse) : undefined
        }
      />
      <div className={styles.header}>
        <Text
          text="Explore courses"
          textKey="courses_title"
          fontSize="h2"
          fontWeight="bold"
        />
        <div className={styles.search}>
          <InputField
            helperText="Search for courses"
            helperTextKey="courses_searchBar"
            type="search"
            name="search"
            value={searchInputState}
            onChange={onSearchChange}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.staticMenu}>
          <StaticMenu
            menuOptions={menuOptions}
            selected={selectedMenuItem}
            onChange={onChangeMenuItem}
          />
        </div>
        {!courses && (
          <div className={styles.coursesList}>
            <div className={styles.coursesListItems}>
              <Skeleton type="courseCard" />
              <Skeleton type="courseCard" />
            </div>
          </div>
        )}
        {courses &&
          (courses.length > 0 ? (
            <div className={styles.coursesList}>
              <div className={styles.coursesListItems}>
                {courses.map((course) => (
                  <CourseCard
                    key={'CourseCard-' + course._id}
                    course={course}
                    onStartCourse={() => onCourseCardClick(course)}
                  />
                ))}
              </div>
              {(paginationData?.totalCount ?? 0) >
                PAGINATION_LIMIT__COURSES && (
                <div className={styles.pagination}>
                  <PaginationControls
                    paginationData={paginationData}
                    onChangePageClick={onChangePageClick}
                    limit={PAGINATION_LIMIT__COURSES}
                  />
                </div>
              )}
            </div>
          ) : paginationData?.search ? (
            notFoundBySearchComponent()
          ) : (
            emptyCoursesComponent()
          ))}
      </div>
    </div>
  );
};
