import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { LoadingState } from './types';

const initialState: LoadingState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

// export the reducers
export const { setLoading } = loadingSlice.actions;

export const selectLoadingState = (state: RootState) => state.loading.isLoading;

export default loadingSlice.reducer;
