import { initReactI18next } from 'react-i18next';
import {
  i18n,
  availableLanguageCodes,
  defaultLanguage,
  LanguageCode,
  getTranslationKey as commonGetTranslationKey,
  initI18n,
} from '@mono/common';

const changeLanguage = i18n.changeLanguage;

const localStorageLanguageKey = 'language';

export const getCurrentLanguageCode = (): LanguageCode => {
  return getCurrentLanguageCodeFromLocalStorage() || defaultLanguage;
};

export const getAvailableLanguageCodes = (): LanguageCode[] => {
  return availableLanguageCodes;
};

const getCurrentLanguageCodeFromLocalStorage = (): LanguageCode | undefined => {
  const item: string | null = localStorage.getItem(localStorageLanguageKey);
  if (!item) {
    return undefined;
  }

  try {
    return item as LanguageCode;
  } catch (e) {
    return undefined;
  }
};

export const updateLanguage = (language: LanguageCode) => {
  if (!availableLanguageCodes.includes(language)) {
    return;
  }
  changeLanguage(language);

  // TODO: make sure we have consent to store the information
  localStorage.setItem(localStorageLanguageKey, language);
};

export const getTranslationKey = commonGetTranslationKey;

initI18n(
  {
    lng: getCurrentLanguageCodeFromLocalStorage() || defaultLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  },
  [initReactI18next],
);

export const i18nWeb = i18n;
