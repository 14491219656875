import { useTranslation } from 'react-i18next';
import { Images } from '../../assets/images';
import { Button, Text } from '../../atoms';
import { Skeleton } from '../Skeleton';
import { AcademyLogo } from '../AcademyLogo';
import styles from './HeroSection.module.css';
import { CourseStatus, UserCourseResult } from '../../utils/interfaces';

interface HeroSectionProps {
  name: string;
  course?: UserCourseResult | null;
  onGoToCourse?: () => void;
}

export const HeroSection = (props: HeroSectionProps) => {
  const { name, course, onGoToCourse } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src={Images.CosafeTrainingSession} alt="" className={styles.img} />
      <div className={styles.overlay}>
        <div className={styles.logoContainer}>
          <AcademyLogo />
        </div>
        <Text
          text={t('hero_welcome', {
            name,
          })}
          fontSize="h2"
          fontWeight="bold"
        />
        {course === undefined && (
          <div className={styles.skeleton}>
            <Skeleton type="h2" />
          </div>
        )}
        {course && (
          <>
            <Text
              text={'Time to sharpen your skills in'}
              textKey="hero_course_introduction"
              fontSize="h2"
              fontWeight="bold"
            />
            <Text
              text={course.title}
              textKey={course.titleKey}
              fontSize="h2"
              fontWeight="bold"
              variant="attention"
            />
            <img src={Images.LinesBlue} alt={'Underlining'} />
            {onGoToCourse && (
              <div className={styles.button}>
                <Button
                  text={
                    course.status === CourseStatus.Ongoing
                      ? 'Continue course'
                      : 'Start course'
                  }
                  textKey={
                    course.status === CourseStatus.Ongoing
                      ? 'course_continue_button'
                      : 'course_start_button'
                  }
                  size="full"
                  onClick={onGoToCourse}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
