import React from 'react';
import { Text } from '../../atoms';
import StyledClass from './InfoSet.module.css';
import { Icons } from '../../assets/icons';

type InfoFieldIconType = 'clock' | 'document';

interface InfoField {
  icon: InfoFieldIconType;
  text: string;
}

interface InfoSetProps {
  infoFields: InfoField[];
}

export const InfoSet = (props: InfoSetProps) => {
  const { infoFields } = props;

  const getIcon = (iconType: InfoFieldIconType) => {
    switch (iconType) {
      case 'clock':
        return <Icons.Clock className={StyledClass.icon} />;
      case 'document':
        return <Icons.Document className={StyledClass.icon} />;
    }
  };

  const renderInfoFields = () => {
    return infoFields.map((infoField, index) => (
      <React.Fragment key={index}>
        {index !== 0 && <div className={StyledClass.divider} />}
        {getIcon(infoField.icon)}
        <Text text={infoField.text} fontSize="body" fontWeight="medium" />
      </React.Fragment>
    ));
  };

  return <div className={StyledClass.container}>{renderInfoFields()}</div>;
};
