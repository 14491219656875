import { ReadUserProfile } from '../../interfaces/academy-api';
import { academyApi } from './academyApi';

export const getProfile = async (): Promise<ReadUserProfile> => {
  try {
    const response = await academyApi.request<ReadUserProfile>({
      resourcePath: '/user-profiles',
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching user profile', error);
    throw error;
  }
};
