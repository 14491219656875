import { useState, useEffect, useRef } from 'react';

const TIMEOUT = 100;

interface WindowDimensions {
  width: number;
  height: number;
}

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

function getWindowDimensions(): WindowDimensions {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return { width: 0, height: 0 };
}

function getBreakpoint(width: number): string {
  if (width < breakpoints.sm) return 'xs';
  if (width < breakpoints.md) return 'sm';
  if (width < breakpoints.lg) return 'md';
  if (width < breakpoints.xl) return 'lg';
  return 'xl';
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions(),
  );
  const breakpointRef = useRef(getBreakpoint(windowDimensions.width));

  useEffect(() => {
    const handleWindowsSizeChange = () => {
      const dimensions = getWindowDimensions();
      if (
        dimensions.width !== windowDimensions.width ||
        dimensions.height !== windowDimensions.height
      ) {
        setWindowDimensions(dimensions);
      }
    };

    let debounceTimeout: NodeJS.Timeout;
    const debouncedHandleResize = () => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(handleWindowsSizeChange, TIMEOUT);
    };

    const handleResize = () => {
      const dimensions = getWindowDimensions();
      const newBreakpoint = getBreakpoint(dimensions.width);
      if (newBreakpoint !== breakpointRef?.current) {
        breakpointRef.current = newBreakpoint;
        setWindowDimensions(dimensions);
        clearTimeout(debounceTimeout);
      } else {
        debouncedHandleResize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowDimensions]);

  return windowDimensions;
}
