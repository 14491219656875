/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styles from './CoursesPageModal.module.css';
import { getLanguageByCode } from '../../utils/frontendUtils';
import { getCurrentLanguageCode } from '../../i18n';
import { Icons } from '../../assets/icons';
import { IconButton } from '../../atoms/IconButton';
import { SidebarLanguageSelector } from '../../molecules/SidebarLanguageSelector';
import ButtonStyles from '../../atoms/MenuItem/MenuItem.module.css';
import { AcademyLogo } from '../../molecules';

export interface CoursesPageModalProps {
  isMenuOpen: boolean;
  logoutRequest: () => Promise<unknown>;
  logoutUser: () => void;
}

export const CoursesPageModal = (props: CoursesPageModalProps) => {
  const { isMenuOpen, logoutRequest, logoutUser } = props;

  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<
    boolean | undefined
  >(undefined);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [isClosing, setIsClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(isMenuOpen);
  const [isOpen, setIsOpen] = useState(isMenuOpen);

  const getSelectLanguageBtnIcon = () => {
    return (
      <div>
        {getLanguageByCode(getCurrentLanguageCode(), styles.flag).icon}
        <Icons.ChevronRight className={ButtonStyles.chevronRightIcon} />
      </div>
    );
  };

  const getLogoutBtnIcon = () => {
    return (
      <>
        {isLoggingOut ? (
          <Icons.Loader className={styles.loaderIcon} />
        ) : (
          <Icons.Door className={styles.icon} />
        )}
      </>
    );
  };

  const showMainButtons =
    isLanguageSelectorOpen === false || isLanguageSelectorOpen === undefined;

  const handleLogout = async () => {
    if (isLoggingOut) return;
    try {
      setIsLoggingOut(true);
      await logoutRequest();
      logoutUser();
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      setIsOpen(true);
      setIsVisible(true);
      setIsClosing(false);
    } else if (isVisible) {
      setIsClosing(true);
      setIsOpen(false);
    }
  }, [isMenuOpen]);

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsVisible(false);
      setIsClosing(false);
    }
  };
  return (
    <>
      {isVisible && (
        <div
          className={`${styles.modal} ${isOpen ? styles.open : ''} ${isClosing ? styles.close : ''}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <div className={styles.logoContainer}>
            <AcademyLogo />
          </div>
          {showMainButtons && (
            <div
              className={`${isLanguageSelectorOpen === false && styles.showMainButtons}`}
            >
              <IconButton
                size="full-auto"
                type="menu"
                justifyContent="between"
                text="Select language"
                textKey="select_language"
                bottomBorder
                rightIcon={getSelectLanguageBtnIcon()}
                onClick={() => {
                  setIsLanguageSelectorOpen(true);
                }}
              />
              <IconButton
                size="full-auto"
                type="menu"
                text={isLoggingOut ? 'Logging out...' : 'Log out'}
                textKey={isLoggingOut ? 'logging_out' : 'user_logout'}
                justifyContent="start"
                leftIcon={getLogoutBtnIcon()}
                onClick={handleLogout}
              />
            </div>
          )}
          {isLanguageSelectorOpen === true && (
            <div className={`${styles.languageSelector}`}>
              <SidebarLanguageSelector
                handleClose={() => {
                  setIsLanguageSelectorOpen(false);
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
