import styles from './AcademyLogo.module.css';
import { Brand } from '../../assets/brand';
import { AcademyBadge } from '../../atoms';
import { Link, useNavigate } from 'react-router-dom';
import { AcademyBadgeProps } from '../../atoms/AcademyBadge';
import { MouseEvent } from 'react';

interface AcademyLogoProps {
  /** @default 'light' */
  type?: AcademyBadgeProps['type'];
  /** @default 'false' */
  goHomeOnClick?: boolean;
}

/**
 * @description Will fit into your layout by width or height of parent node.
 * @usage To control the size, You should wrap it into a container with 'width' or 'height' (or both) set. It will expand adaptively
 * @note min: 168x26, max: 290x48
 */
export const AcademyLogo = (props: AcademyLogoProps) => {
  const navigate = useNavigate();
  const badgeType = props.type || 'light';

  const onLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    !!props.goHomeOnClick && navigate('/');
  };

  return (
    <Link
      to={'/'}
      onClick={onLinkClick}
      className={`${styles.container} ${!props.goHomeOnClick ? styles.disabled : ''}`}
    >
      <img
        className={`${styles.cosafeLogo}`}
        src={Brand.CosafeLogo}
        alt={'Cosafe Logo'}
      />
      <div className={styles.badgeContainer}>
        <AcademyBadge type={badgeType} />
      </div>
    </Link>
  );
};
