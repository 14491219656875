import { useSelector } from 'react-redux';
import { selectLoadingState } from '../../redux/LoadingSlice';
import { Icons } from '../../assets/icons';
import styles from './Loader.module.css';

export const Loader = () => {
  const isLoading = useSelector(selectLoadingState);
  if (!isLoading) return null;

  return (
    <div className={styles.container}>
      <Icons.Loader className={styles.icon} />
    </div>
  );
};
