import styles from './Badge.module.css';
import { Icons } from '../../assets/icons';
import { ReactElement } from 'react';
import { Text } from '../Text';

export enum BadgeType {
  active = 'active',
  completed = 'completed',
  notStarted = 'notStarted',
  premium = 'premium',
}
interface BadgeProps {
  type: BadgeType;
}

interface BadgeDetails {
  text: string;
  textKey: string;
  icon: ReactElement;
}

const getBadgeDetails = (type: BadgeType): BadgeDetails => {
  switch (type) {
    case BadgeType.active:
      return {
        text: 'Active',
        textKey: 'badge_active',
        icon: <Icons.RadioChecked className={styles.icon} />,
      };
    case BadgeType.completed:
      return {
        text: 'Completed',
        textKey: 'badge_completed',
        icon: <Icons.CheckmarkRound className={styles.icon} />,
      };
    case BadgeType.notStarted:
      return {
        text: 'Not started',
        textKey: 'badge_notStarted',
        icon: <Icons.Calendar className={styles.icon} />,
      };
      case BadgeType.premium:
        return {
          text: 'Premium',
          textKey: 'badge_premium',
          icon: <Icons.Crown className={styles.icon} />,
        };
  }
};

export const Badge = (props: BadgeProps) => {
  const { type } = props;
  const { text, textKey, icon } = getBadgeDetails(type);
  return (
    <div className={`${styles.badge} ${styles[type]}`}>
      {icon}
      <div className={styles.text}>
        <Text
          textKey={textKey}
          text={text}
          fontSize="titleSmall"
          fontWeight="regular"
          variant="complementary"
        />
      </div>
    </div>
  );
};
