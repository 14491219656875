import { LanguageSelector } from '../../molecules/LanguageSelector';
import styles from './ModuleSideBar.module.css';
import { UserCard } from '../../molecules/UserCard';
import { UserCourseResult } from '../../utils/interfaces';
import { AcademyBadge, Text } from '../../atoms';
import { Brand } from '../../assets/brand';
import { Step, StepIconType, StepTracker } from '../../molecules/StepTracker';
import { useTranslation } from 'react-i18next';
import { SidebarLanguageSelector } from '../../molecules/SidebarLanguageSelector';
import { useState } from 'react';
import { Icons } from '../../assets/icons';
import { getLanguageByCode } from '../../utils/frontendUtils';
import { getCurrentLanguageCode } from '../../i18n';
import { IconButton } from '../../atoms/IconButton';
import { logoutRequest } from '../../utils/api/legacy-api/auth-api/auth';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/Login/LoginSlice/actionCreators';
import { AcademyLogo, Skeleton } from '../../molecules';

interface ModuleSideBarProps {
  userName: string;
  userPhoto: string | null;
  course: UserCourseResult;
  activeModuleId?: string;
  doLogout: () => void;
  doNavigateToModule: (moduleId: string) => void;
}

export const ModuleSideBar: React.FC<ModuleSideBarProps> = (
  props: ModuleSideBarProps,
) => {
  const {
    userName,
    userPhoto,
    course,
    activeModuleId,
    doLogout,
    doNavigateToModule,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);
  const [haveSlideOutClass, setHaveSlideOutClass] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const moduleSteps: Step[] = course.modules.map((module) => {
    const active: boolean = activeModuleId === module._id;
    const iconType: StepIconType = active ? 'selected' : 'empty';
    return {
      title: module.title,
      titleKey: module.titleKey,
      subtitle: t('duration_min', { count: module.durationMin }),
      active,
      iconType: module.completed ? 'check' : iconType,
      onClick: () => doNavigateToModule(module._id),
    };
  });

  const changeLanguagesSelectionState = (state: boolean) => {
    setHaveSlideOutClass(true);
    setTimeout(() => {
      setIsLanguageSelectOpen(state);
      setHaveSlideOutClass(false);
    }, 200);
  };

  const getSelectLanguageBtnIcon = () => {
    return (
      <div className={styles.innerRow}>
        {getLanguageByCode(getCurrentLanguageCode(), styles.flag).icon}
        <Icons.ChevronRight className={styles.icon} />
      </div>
    );
  };

  const getLogoutBtnIcon = () => {
    return (
      <>
        {isLoggingOut ? (
          <Icons.Loader className={styles.loaderIcon} />
        ) : (
          <Icons.Door className={styles.icon} />
        )}
      </>
    );
  };

  const handleLogout = async () => {
    if (isLoggingOut) return;
    try {
      setIsLoggingOut(true);
      await logoutRequest();
      dispatch(logoutUser());
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <div className={styles.sidebar}>
      {!isLanguageSelectOpen && (
        <div
          className={`${styles.sidebarContent} ${haveSlideOutClass && styles.close}`}
        >
          <div className={styles.headerLogo}>
            <AcademyLogo goHomeOnClick />
          </div>
          <div className={styles.content}>
            <div className={styles.courseTitle}>
              <Text
                text={course.title}
                textKey={course.titleKey}
                fontSize="body"
                fontWeight="bold"
                truncate="twoLines"
              />
            </div>
            <div className={styles.steps}>
              <StepTracker steps={moduleSteps} />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.userCard}>
              <UserCard
                name={userName}
                photoFileName={userPhoto}
                hasBorder={true}
                doLogout={doLogout}
                expansionDirection="up"
              />
            </div>
            <div className={styles.LanguageSelector}>
              <LanguageSelector hasBorder={true} expansionDirection="up" />
            </div>
          </div>
          <div className={styles.mobileFooter}>
            <IconButton
              size="full"
              type="bare"
              text="Select language"
              textKey="select_language"
              rightIcon={getSelectLanguageBtnIcon()}
              onClick={() => changeLanguagesSelectionState(true)}
            />
            <div className={styles.mobileFooterSeparator} />
            <IconButton
              size="full"
              type="bare"
              text={isLoggingOut ? 'Logging out...' : 'Log out'}
              textKey={isLoggingOut ? 'logging_out' : 'user_logout'}
              justifyContent="start"
              leftIcon={getLogoutBtnIcon()}
              onClick={handleLogout}
            />
          </div>
        </div>
      )}
      {isLanguageSelectOpen && (
        <div
          className={`${styles.sidebarContent} ${haveSlideOutClass && styles.close}`}
        >
          <SidebarLanguageSelector
            handleClose={() => changeLanguagesSelectionState(false)}
          />
        </div>
      )}
    </div>
  );
};

export const ModuleSideBarLoader = () => {
  return (
    <div className={styles.sidebar}>
      <div className={`${styles.sidebarContent}`}>
        <div className={styles.header}>
          <img
            className={styles.logo}
            src={Brand.CosafeLogo}
            alt={'Cosafe Logo'}
          />
          <AcademyBadge type="light" />
        </div>
        <div className={styles.content}>
          <div className={styles.courseTitle}>
            <Skeleton type="label" />
          </div>
          <div className={`${styles.steps} ${styles.loaderSteps}`}>
            <Skeleton type="h1" />
            <Skeleton type="h1" />
          </div>
        </div>
      </div>
    </div>
  );
};
