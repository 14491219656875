import React, { useState } from 'react';
import styles from './LanguageSelector.module.css';
import {
  getCurrentLanguageCode,
  getAvailableLanguageCodes,
  updateLanguage,
} from '../../i18n';
import { GreatBritainFlag } from '../../assets/icons/flags/GreatBritainFlag';
import { SwedenFlag } from '../../assets/icons/flags/SwedenFlag';
import { NetherlandsFlag } from '../../assets/icons/flags/NetherlandsFlag';
import { MenuOption, StaticMenu } from '../StaticMenu';
import { LanguageCode } from '@mono/common';

const languages = {
  en: {
    name: 'English (UK)',
    icon: <GreatBritainFlag className={styles.flag} />,
  },
  se: { name: 'Svenska', icon: <SwedenFlag className={styles.flag} /> },
  nl: {
    name: 'Nederlands',
    icon: <NetherlandsFlag className={styles.flag} />,
  },
};

interface LanguageSelectorProps {
  expansionDirection?: 'up' | 'down';
  isInitiallyExpanded?: boolean;
  hasBorder: boolean;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const { expansionDirection = 'down', isInitiallyExpanded, hasBorder } = props;

  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded || false);
  const [currentLanguageCode, setCurrentLanguageCode] = useState<LanguageCode>(
    getCurrentLanguageCode(),
  );

  const onLanguageChosen = (languageCode: LanguageCode) => {
    setCurrentLanguageCode(languageCode);
    updateLanguage(languageCode);
    setIsExpanded(false);
  };

  const renderLanguageMenu = () => {
    const menuOptions: MenuOption[] = getAvailableLanguageCodes().map(
      (code) => {
        return {
          key: code as string,
          text: languages[code].name,
          icon: languages[code].icon,
        };
      },
    );
    return (
      <StaticMenu
        menuOptions={menuOptions}
        onChange={(key) => onLanguageChosen(key as LanguageCode)}
        selected={currentLanguageCode}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${isExpanded ? styles.expanded : ''} ${hasBorder ? styles.bordered : ''}`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {languages[currentLanguageCode].icon}
      </div>
      {isExpanded && (
        <div className={`${styles.staticMenu} ${styles[expansionDirection]}`}>
          {renderLanguageMenu()}
        </div>
      )}
    </div>
  );
};
