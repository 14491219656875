import { TextProps } from '.';
import StyledClass from './Text.module.css';

export const getTextClassNames = (props: TextProps) => {
  const {
    fontSize = 'base',
    fontWeight = 'regular',
    fontStyle,
    variant,
    truncate,
    textAlign,
  } = props;
  let classNames = `${StyledClass.container} ${StyledClass[fontSize]} ${StyledClass[fontWeight]}`;
  classNames += fontStyle ? ` ${StyledClass[fontStyle]}` : '';
  classNames += variant ? ` ${StyledClass[variant]}` : '';
  classNames += textAlign ? ` ${StyledClass[textAlign]}` : '';
  classNames += truncate
    ? ` ${StyledClass.truncate} ${StyledClass[truncate]}`
    : '';
  return classNames;
};

export const textIncludesAnyOfTheChars = (text: string, chars: string[]) => {
  if (!text || !chars?.length) return false;

  return chars.some((char) => text.includes(char));
};